<template>
    <div class="ld-container" style="height: 90vh; width: 100%">
        <div class="row" style="height: 100%">
            <div class="list-area col-md-6">
                <div class="row">
                    <header-box :title="`${$t('시스템 관리')} > ${$t('코드 관리')} > ${$t('공통 코드 관리')}`" />
                </div>
                <div style="height: calc(100% - 40px)">
                    <table-list
                        ref="table-list"
                        :title="'공통 코드'"
                        :columns="columns"
                        :rows="codeList"
                        :keyField="'commonCode'"
                        :groupField="'codeCategory'"
                        :toggleFields="['enabled']"
                        :transCodes="transCodes"
                        :controls="controls"
                        :useMaxHeight="true"
                        @button-click="handleListButtonClick"
                        :mode="'edit'"
                        @row-click="handleItemClick"
                        @toggle-changed="handleItemToggle"
                    />
                </div>
            </div>
            <div class="detail-area col-md-6">
                <CommonCodeDetail
                    ref="CommonCodeDetail"
                    :title="'공통 코드'"
                    :commonCode="commonCode"
                    @data-updated="handleDetailUpdated"
                    @closed="handleDetailClosed"
                />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderBox from "@views/component/headerBox/analysis/Header.vue";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";

import CommonCodeDetail from "./CommonCodeDetail.vue";
import backEndApi from "@src/api/backEndApi";

export default {
    components: {
        HeaderBox,
        TableList,
        CommonCodeDetail,
    },
    props: {},
    data() {
        return {
            commonCode: null,
            controls: [
                { event: "delete", label: "삭제", class: "btn-danger" },
                { event: "refresh", label: "갱신", class: "bg-white text-black" },
                {
                    event: "status",
                    label: "사용",
                    class: "bg-white text-black",
                    options: { enabled: "Y" },
                },
                {
                    event: "status",
                    label: "미사용",
                    class: "bg-white text-black ",
                    options: { enabled: "N" },
                },
                // { event: 'status', label: '운영코드지원', class: 'btn-light', options: {oprtnCodeYn:"Y"}, },
                // { event: 'status', label: '운영코드해제', class: 'btn-light', options: {oprtnCodeYn:"N"}, },
                { event: "regist", label: "추가", class: "btn-primary" },
            ],
            transCodes: [
                {
                    field: "enabled",
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: "oprtnCodeYn",
                    codes: this.$store.state.commonCodes.enabled,
                },
            ],
            columns: [
                // 테이블 column
                {
                    label: this.$t("공통 코드"),
                    field: "rowTitle",
                    type: "text",
                    compositionFields: ["codeName", "commonCode"],
                },
                {
                    label: this.$t("카테고리"),
                    field: "codeCategory",
                    type: "text",
                },
                {
                    label: this.$t("설명"),
                    field: "desc",
                    type: "text",
                    sortable: false,
                    tdClass: "left-align-wrap",
                },
                // {
                //     label: this.$t("현장운영코드"),
                //     field: "oprtnCodeYn",
                //     width: "100px",
                //     type: "text",
                //     sortable: false,
                // },
                {
                    label: this.$t("사용여부"),
                    field: "enabled",
                    width: "100px",
                    type: "text",
                    sortable: false,
                },
            ],
            addCodeItem: {
                codeItem: "",
                codeName: "",
                etcInfo1: "",
                etcInfo2: "",
                etcInfo3: "",
                etcInfo4: "",
                etcInfo5: "",
                enabled: "",
            },

            codeList: [], // 공통 코드 리스트
        };
    },
    computed: {},
    created() {},
    async mounted() {
        await this.getDataList();
    },
    methods: {
        // initCodeItem() {
        //     this.addCodeItem = {
        //         codeItem: "",
        //         codeName: "",
        //         etcInfo1: "",
        //         etcInfo2: "",
        //         etcInfo3: "",
        //         etcInfo4: "",
        //         etcInfo5: "",
        //         enabled: ""
        //     }
        // },

        async handleListButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.registNew();
                    break;

                case "delete":
                    await this.deleteChecked();
                    break;

                case "refresh":
                    await this.getDataList(event);
                    break;

                case "status":
                    await this.changeStatusChecked(options);
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },

        async handleItemToggle(key, field, status) {
            await this.changeStatus(key, field, status);
        },

        async handleItemClick(row, idx, toggle, check) {
            if (toggle || check) return;

            const confirm = await this.$refs["CommonCodeDetail"].notifyCancelEdit();
            if (confirm) {
                this.commonCode = row.commonCode;
            }
        },

        /**
         * Table List Management Functions
         */
        async getDataList(event) {
            try {
                let result = await backEndApi.commonCode.getCommonCodeList();
                if (result && result.data) {
                    this.codeList = result.data;
                    if (event) await this.alertNoti("갱신되었습니다.");
                } else {
                    console.error("commonCode.getCommonCodeList API Failed");
                }
                return result;
            } catch (e) {
                console.error(e);
            }
        },

        async deleteChecked() {
            const that = this;

            try {
                const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                if (!confirm.value) return;

                const checked = this.$refs["table-list"].getCheckedRows();
                if (!that.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        await backEndApi.commonCode.deleteCommonCode(item.commonCode);
                    });
                    await this.alertNoti("선택한 목록을 삭제하였습니다.");
                    let clear = await this.getDataList();
                    if (clear) await this.$refs["CommonCodeDetail"].clearData();
                    else this.codeList = null;
                } else {
                    this.alertNoti("목록을 선택해주세요.");
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e);
            }
        },

        async changeStatusChecked(options) {
            const that = this;

            let data = options;

            try {
                const checked = this.$refs["table-list"].getCheckedRows();
                if (!that.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        if (!that.isEmpty(data)) {
                            data["commonCode"] = item.commonCode;

                            if (data.hasOwnProperty("enabled")) {
                                await backEndApi.commonCode.setEnabled(data);
                            } else if (data.hasOwnProperty("oprtnCodeYn")) {
                                await backEndApi.commonCode.setOprtnCodeYn(data);
                            } else {
                                console.warn("Unknown Status Name");
                            }
                        }
                    });
                    await this.alertNoti("변경하였습니다.");
                    await that.getDataList();
                } else {
                    this.alertNoti("목록을 선택해주세요.");
                }
            } catch (e) {
                console.error(e);
                this.alertNoti("상태 변경에 실패하였습니다.");
            }
        },

        async changeStatus(key, field, status) {
            const data = {};
            data["commonCode"] = key;
            data[field] = status;

            try {
                let result = await backEndApi.commonCode.setEnabled(data);
                if (result.data) {
                    await this.alertNoti("변경하였습니다.");
                    await this.getDataList();
                }
            } catch (e) {
                console.error(e);
                this.alertNoti("상태 변경에 실패하였습니다.");
            }
        },

        async registNew() {
            this.$refs["CommonCodeDetail"].newData();
        },

        // Event Handling
        async handleDetailUpdated(type, data) {
            console.log(type);
            console.log(data);
            // reload
            await this.getDataList();
        },

        handleDetailClosed() {
            this.commonCode = null;
        },
    },
};
</script>

<style scoped>
.ld-container {
    height: 90vh;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;

    padding: 10px 30px 10px 20px;
}

.ld-container .list-area {
    height: 100%;
    overflow: hidden;
    padding: 10px;
    /* border-right: 1px solid #ececec; */
}

.ld-container .detail-area {
    /* display: flex; */
    /* flex-direction: column; */
    /* flex-wrap: nowrap; */
    /* flex: 1; */

    height: 100%;
    margin-right: 00px;
    padding: 0px 10px 0px 10px;
    border-top: 1px solid #f4f4f4;
    border-right: 2px solid #e7e7e7;
    border-bottom: 2px solid #e7e7e7;
    border-left: 1px solid #f4f4f4;
    background-color: #f9f9f9;
    border-radius: 10px;
}
</style>
